export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/distributor/yinqianbao/back/account-management',
          name: 'distributor-yinqianbao-back-account-management',
          component: () => import(/* webpackChunkName: "page-distributor-yinqianbao-back-account-management" */ './1.0.1/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `余额记账`},
              {name: `余额账户`},
            ],
          },
        },
      ]
    },
  ]
};
