import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/sample-data-list',
            name: 'admin-sample-data-list',
            component: () => import(/* webpackChunkName: "page-admin-default-sample-data-list" */ `./index.vue`),
            meta: {
                data_for_admin_layout: {
                    data_for_history_tag: {
                        entity: {
                            name: "示例数据"
                        }
                    },
                    data_for_breadcrumb: {
                        entity_list: [
                            {
                                name: "示例功能"
                            },
                            {
                                name: "示例数据"
                            }
                        ]
                    }
                },
                enable_keep_alive: true
            },

        }
    ],
};
