export default {
  path: '/hotel',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    // {
    //   path: '/',
    //   name: 'hotel',
    //   component: () => import ('@/page/distributor/hotel/hotel-home/1.0.1/index.vue'),
    // },
    // {
    //   path: '/hotel-search',
    //   name: 'hotelSearch',
    //   component: () => import ('@/page/distributor/hotel/front/hotel-search/1.0.1/index.vue'),
    // },
    // {
    //   path: '/distributor/hotel/front/hotel-reserve',
    //   name: 'distributor-hotel-front-hotel-reserve',
    //   component: () => import ('@/page/distributor/hotel/front/hotel-reserve/1.0.0/index.vue'),
    // },
    // {
    //   path: '/hotel-order-pay',
    //   name: 'hotelOrderPay',
    //   component: () => import ('@/page/distributor/hotel/front/hotel-pay/1.0.0/index.vue'),
    // },
    // {
    //   path: '/hotel-order-pay-success',
    //   name: 'hotelOrderPaySuccess',
    //   component: () => import ('@/page/distributor/hotel/front/hotel-pay-success/1.0.0/index.vue'),
    // },
    // {
    //   path: '/distributor/hotel/front/hotel-details',
    //   name: 'distributor-hotel-front-hotel-details',
    //   component: () => import ('@/page/distributor/hotel/front/hotel-details/1.0.0/index.vue'),
    // },
  ]
}


