export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        // 机票-前台查询首页
        {
            path: '/air-ticket',
            name: 'air-ticket',
            component: () => import('@/page/distributor/air-ticket/flight-home/1.0.3/index.vue')
        },
        //国际机票
        {
            path: '/international/air-list',
            name: 'international-air-list',
            component: () => import('@/page/distributor/air-ticket/internationalAir/internationalAirList.vue')
        },
        //国际机票订单
        {
            path: '/international/air-order',
            name: 'international-air-order',
            component: () => import('@/page/distributor/air-ticket/internationalAir/internationalAirOrder.vue')
        },
        // 机票-前台订单支付页
        {
            path: '/airPay/page',
            name: 'air-pay-page',
            component: () => import('@/page/distributor/air-ticket/airPayPage.vue')
        },
        // 机票-前台支付成功页面
        {
            path: '/paySuccess',
            name: '支付成功',
            component: () => import('@/page/distributor/air-ticket/paySuccess.vue')
        }
    ]
}
