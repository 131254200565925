export default {
    name: ``,
    path: '',
    component: () => import(/* webpackChunkName: "page-supplier-registration-supplierLogin" */ `@/page/supplier/registration/index.vue`),
    children: [
        {
            name: `supplier-registration`,
            path: '/supplier/registration',
            component: () => import(/* webpackChunkName: "page-supplier-registration-supplierLogin" */ `./2.0.0/index.vue`),
            meta: {
                enable_check_token: false,
            },
        },
    ]
};
