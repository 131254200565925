import import_latest_version_module from '@/page/redirect/import_latest_version_module';

const get_route_root = function () {
    const r = {
        name: `root`,
        path: '/',
        component: () => import_latest_version_module(),
        // redirect(to) {
        //     // 方法接收目标路由作为参数
        //     // return 重定向的字符串路径/路径对象
        //     return {
        //         path: '/search',
        //         query: {
        //             q: to.params.searchText,
        //         },
        //     };
        // },
        meta: {
            // enable_check_token: false,
        },

    };

    return r;
};

export default get_route_root;
