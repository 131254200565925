export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/credit/back',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/foreign/accounts/bill',
                    name: 'foreign-accounts-bill',
                    component: () => import(/* webpackChunkName: "page-distributor-accountsChecking-foreignAccountsChecking" */ './index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            { name: `对账管理` },
                            { name: `国际对账` },
                        ],
                    },
                },
            ]
        },
    ]
};
