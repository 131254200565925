export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
      {
        path: '',
        component: () => import('@/page/distributor/component/admin-layout/index.vue'),
        children: [
          {
            path: '/air-ticket/admin/international/list/order/detail',
            name: 'air-ticket-admin-international-list-order-detail',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-internationalAir-admin-component" */ './lookDetail.vue'),
            meta: {
              nav_list_for_admin_layout_head_nav: [
                {name: '国际机票订单'},
                {name: '订单详情'},
              ],
            },
          },
        ]
      },
    ]
  };
