export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/tour/back',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/distributor/tour/back/tour-with-group-order-list',
                    name: 'distributor-tour-back-tour-with-group-order-list',
                    component: () => import(/* webpackChunkName: "page-distributor-tour-back-tourWithGroupOrder" */ '@/page/distributor/tour/back/tourWithGroupOrder/1.0.2/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            { name: `旅游订单` },
                            { name: `跟团游列表` },
                        ],
                    },
                },
                {
                    path: '/distributor/tour/back/tour-order-detail',
                    name: 'distributor-tour-back-tour-order-detail',
                    component: () => import(/* webpackChunkName: "page-distributor-tour-back-tourWithGroupOrder" */ '@/page/distributor/tour/back/component/orderDetail/1.0.5/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            { name: `旅游订单` },
                            { name: `订单详情` },
                        ],
                    },
                }
            ]
        },
    ]
};
