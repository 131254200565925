import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import to_boolean from 'nllib/src/convert/to_boolean';

import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";

import aRouter from "@/router";
import all_routes_processor from "@/router/init_router/lib/all_routes_processor";
import check_token_processor from "@/router/init_router/lib/check_token_processor";
import global_query_processor from "@/router/init_router/lib/global_query_processor";
import redirect_to_login_page from '@/lib/common-service/redirect_to_login_page';

const init_route_guard = function () {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    aRouter.beforeEach((to, from, next) => {
        try {

            const route_guard = to.params.route_guard || {};
            const enable_route_guard = to_boolean(route_guard.enable, true);
            if (!enable_route_guard) {
                next();
                return;
            }

            Q.when()
                .then(function () {
                    return check_token_processor.next({to, from, next});
                })
                .then(function (data) {
                    const check_token_type = data.check_token_type;
                    if (check_token_type === `001`) {
                        //const msg = `check_token_processor::to_name=${to_name}|enable_check_token=false，不需要执行check_token操作，check_token_type=001`;
                        next();
                        return;
                    }
                    if (check_token_type === `002`) {
                        //const msg = `check_token_processor::to_name=${to_name}|token为假值，不需要执行check_token操作，check_token_type=002`;
                        next();
                        return;
                    }
                    if (check_token_type === `101`) {
                        //const msg = `check_token_processor::to_name=${to_name}|check_token操作验证通过，check_token_type=101`;
                        return Q.when()
                            .then(function () {
                                let clear_cache;
                                if (to.name === `redirect-to-user-front-home`) {
                                    clear_cache = true;
                                } else {
                                    clear_cache = false;
                                }

                                return get_user_info({
                                    clear_cache,
                                });
                            })
                            .then(function (data_get_user_info) {
                                //userType
                                //1-供应商，2-供应商员工，3-采购商，4-采购商员工， 5-平台， 6-游客（EDI）， 7-临时分销渠道

                                //auditStatus
                                //分销商
                                //0-待审核 1-审核失败 2-部分启用 3-启用 4-平台禁用 5-已删除 6-未签约 7-签约失败

                                //auditStatus
                                //供应商
                                //0-待审核 1-审核失败 2-部分启用 3-启用 4-平台禁用 5-已删除 6-未签约 7-签约失败 8-待设置合同文件

                                //供应商，当且仅当【auditStatus】为【0-待审核】、【1-审核失败】、【6-未签约】、【7-签约失败】、【8-待设置合同文件】时，
                                //会跳转到分销商的这个项目
                                //由于分销商的【auditStatus】与供应商的【auditStatus】的值是一致的，并且值的范围是包含关系的
                                //所以，这里不再根据userType进行分支判断

                                const auditStatus = data_get_user_info.auditStatus;
                                // const auditStatus = 0;
                                // const auditStatus = 1;
                                // const auditStatus = 2;
                                // const auditStatus = 3;
                                // const auditStatus = 4;
                                // const auditStatus = 5;
                                // const auditStatus = 6;
                                // const auditStatus = 7;
                                // const auditStatus = 8;

                                //0-待审核
                                if (auditStatus === 0) {
                                    next({
                                        name: `register-success`,
                                        query: {
                                            type: 1,
                                        },
                                        params: {
                                            route_guard: {
                                                enable: false,
                                            },
                                        }
                                    });
                                    return;
                                }

                                //1-审核失败
                                if (auditStatus === 1) {
                                    next({
                                        name: `audit-message`,
                                        query: {},
                                        params: {
                                            route_guard: {
                                                enable: false,
                                            },
                                        },
                                    });
                                    return;
                                }

                                //2-部分启用 3-启用
                                if (_.contains([2, 3], auditStatus)) {
                                    //正常登录
                                    next({
                                        params: {
                                            route_guard: {
                                                enable: false,
                                            },
                                        }
                                    });
                                    return;
                                }

                                //4-平台禁用 5-已删除
                                if (_.contains([4, 5], auditStatus)) {
                                    //理论上不可能会进入到这里
                                    console.error(`auditStatus=${auditStatus}`);
                                    next(false);
                                    return;
                                }

                                //6-未签约
                                if (auditStatus === 6) {
                                    next({
                                        name: `register-success`,
                                        query: {
                                            type: 3,
                                        },
                                        params: {
                                            route_guard: {
                                                enable: false,
                                            },
                                        }
                                    });
                                    return;
                                }

                                //7-签约失败
                                if (auditStatus === 7) {
                                    next({
                                        name: `register-success`,
                                        query: {
                                            type: 2,
                                        },
                                        params: {
                                            route_guard: {
                                                enable: false,
                                            },
                                        }
                                    });
                                    return;
                                }

                                //8-待设置合同文件
                                if (auditStatus === 8) {
                                    next({
                                        name: `register-success`,
                                        query: {
                                            type: 1,
                                        },
                                        params: {
                                            route_guard: {
                                                enable: false,
                                            },
                                        }
                                    });
                                    return;
                                }

                            })
                            ;
                    }
                    if (check_token_type === `102`) {
                        //const msg = `check_token_processor::to_name=${to_name}|check_token操作验证不通过，check_token_type=102`;
                        redirect_to_login_page();
                        return;
                    }
                    if (check_token_type === `901`) {
                        //const msg = `check_token_processor::to_name=${to_name}|check_token操作验证不通过，check_token_type=901`;
                        redirect_to_login_page();
                        return;
                    }


                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })

        } catch (ex) {
            console.error(ex);
        }
    });
};
export default init_route_guard;
