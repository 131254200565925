export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/hotel/front/hotel-pay',
      name: 'distributor-hotel-front-hotel-pay',
      meta: {
        footer_margin_none: true,
      },
      component: () => import(/* webpackChunkName: "page-distributor-hotel-front-hotel-pay" */ '@/page/distributor/hotel/front/hotel-pay/1.0.1/index.vue'),
    },
  ]
};
