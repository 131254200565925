import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/global-query-test/page-003',
            name: 'admin-global-query-test-page-003',
            component: () => import(/* webpackChunkName: "page-admin-default-global-query-parameter-test-page-003" */ `./index.vue`),
            meta: {
                data_for_admin_layout: {
                    data_for_history_tag: {
                        entity: {
                            name: "page-003"
                        }
                    },
                    data_for_breadcrumb: {
                        entity_list: [
                            {
                                name: "page-003"
                            },
                            {
                                name: "指定路径自动传入全局设置的query参数"
                            },
                            {
                                name: "主页"
                            }
                        ]
                    }
                }
            },

        },
    ],
};
