import to_boolean from 'nllib/src/convert/to_boolean';

const get_enable_check_token = function (pParameter) {
    if (!pParameter) pParameter = {};
    const $route = pParameter.$route || {};

    const meta = $route.meta || {};
    const __enable_check_token = meta.enable_check_token;
    const enable_check_token = to_boolean(__enable_check_token, true);
    return enable_check_token;
};
export default get_enable_check_token;
