export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/train/front/reserve',
      name: 'distributor-train-front-reserve',
      component: () => import(/* webpackChunkName: "page-distributor-train-front-reserve" */ './1.0.2/index.vue'),
    },
  ]
};
