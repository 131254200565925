export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        // 机票-前台机票下单资料填写页
        {
            path: '/air-orders',
            name: 'airOrders',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-airOrders" */ './1.1.1/index.vue')
        },
    ]
}
