export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/air-ticket/international-pay',
            name: 'international-pay',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-internationalAir-airPay" */ './index.vue'),
        },
    ]
};
