const init_baidu_tongji = function () {
    if (window.appConfig.environment !== `release`) return;
    var _hmt = _hmt || [];
    (function () {
        var hm = document.createElement('script');
        hm.src = 'https://hm.baidu.com/hm.js?263a1fe31fd77d15d0402f3f1f4e2b24';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
    })();
};

export default init_baidu_tongji;
