export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/train/back',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/distributor/train/back/train-change-detail',
          name: 'distributor-train-back-train-change-detail',
          component: () => import(/* webpackChunkName: "page-distributor-train-back-train-change-detail" */ '@/page/distributor/train/back/train-change-detail/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `火车票订单`},
              {name: `改签列表`},
              {name: `改签详情`},
            ],
          },
        },
      ]
    },
  ]
};
