import _ from 'underscore';

import aRouter from "@/router";
import get_routes_by_require from '@/router/init_router/lib/get_routes_by_require';
import get_route_root from "@/router/init_router/get_route_root";

import router_distributor_air_ticket_back from '@/router/distributor/air-ticket/back'
import router_distributor_air_ticket_front from '@/router/distributor/air-ticket/front'
import router_distributor_hotel_back from '@/router/distributor/hotel/back'
import router_distributor_hotel_front from '@/router/distributor/hotel/front'

const add_routes = function () {
    const routes_by_require = get_routes_by_require();
    const route_root = get_route_root();

    const routes = [
        route_root,

        ...routes_by_require,

        // 直接引入第三方项目的页面
        // {
        //     path: '/lyn-test',
        //     name: `lyn-test`,
        //     component: () => import(`yinzhilv-frontend-platform-v2/frontend/app-for-npm/src/page/admin/plain-order-management/plain-order-management/index.vue`),
        //     meta: {},
        // },

        {
            path: '*',
            component: () => import(`@/page/error/404/index.vue`),
            meta: {
                enable_check_token: false,
            },
        },

        {
            name: ``,
            path: '',
            component: () => import(`@/page/supplier/registration/index.vue`),
            children: [
                {
                    name: `register-success`,
                    path: '/register/success',
                    component: () => import(`@/page/supplier/registration/loginSuccess.vue`),
                    meta: {
                        enable_check_token: false,
                    },
                },
                {
                    name: `audit-message`,
                    path: '/audit/message',
                    component: () => import(`@/page/supplier/registration/auditMessage.vue`),
                    meta: {
                        enable_check_token: false,
                    },
                },
                {
                    path: '/personage-register',
                    name: 'personage-register',
                    component: () => import('@/page/supplier/personageRegister/index.vue'),
                    meta: {
                        enable_check_token: false,
                    }
                }
            ]
        },
        {
            name: `distributor-h5-registration-success`,
            path: '/distributor/h5-registration-success',
            component: () => import(`@/page/supplier/registration/distributorLogin/h5/registrationSuccess.vue`),
            meta: {
                enable_check_token: false,
            },
        },

        router_distributor_air_ticket_back,
        router_distributor_air_ticket_front,
        router_distributor_hotel_back,
        router_distributor_hotel_front,

    ];

    aRouter.addRoutes(routes);
    return routes;
};

export default add_routes;
