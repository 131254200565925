export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/tour/back',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/distributor/tour/back/corporate-information-management',
          name: 'distributor-tour-back-corporate-information-management',
          component: () => import(/* webpackChunkName: "page-distributor-tour-back-personal-center-corporate-information-management" */ '@/page/distributor/tour/back/personal-center/corporate-information-management/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `个人中心`},
              {name: `公司信息管理`},
            ],
          },
        },
      ]
    },
  ]
};
