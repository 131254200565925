export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/air-ticket/admin/supplement-refund-order-detail',
                    name: 'air-ticket-admin-supplement-refund-order-detail',
                    component: () => import(/* webpackChunkName: "page-distributor-air-ticket-admin-supplement-refund-order-detail" */ './1.0.0/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            {name: `国内机票订单`},
                            {name: `补退订单`},
                        ]
                    },
                },
            ]
        },
    ]
};

