export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/air-ticket/admin/ticketChanging',
          name: 'air-ticket-admin-ticketChanging',
          component: () => import(/* webpackChunkName: "page-distributor-air-ticket-ticketChanging" */ `./1.0.9/index.vue`),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `机票改签` },
            ],
            enable_keep_alive: false,
            need_alive: false,
            refresh: true,
          },
        }
      ],
    }
  ],
};
