import _ from "underscore";
import Q from "q";
import extend from "extend";
import to_string from "nllib/src/convert/to_string";
import to_boolean from "nllib/src/convert/to_boolean";

import get_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_token";
import check_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/check_token";

import all_routes_processor from "@/router/init_router/lib/all_routes_processor";
import redirect_to_login_page from "@/lib/common-service/redirect_to_login_page";
import get_enable_check_token from "@/lib/common-service/route_helper/get_enable_check_token";

const debug = false;

let defer_login_completed = Q.defer();

const handler = function ({to, from, next}) {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    const all_route_list = get_all_route_list();

    const route_name_list_enable_check_token = _.chain(all_route_list)
        .filter(function (m) {
            const enable_check_token = get_enable_check_token({
                $route: m,
            });
            return enable_check_token;
        })
        .map(function (m) {
            return m.name;
        })
        .value()
    ;

    const to_name = to.name;

    if (!_.contains(route_name_list_enable_check_token, to_name)) {
        const msg = `check_token_processor::to_name=${to_name}|enable_check_token=false，不需要执行check_token操作，check_token_type=001`;
        if (debug) console.log(msg);
        return Q.resolve({
            check_token_type: `001`,
            msg,
        });
    }

    if (debug) console.log(`check_token_processor::to_name=${to_name}|enable_check_token=true，需要执行check_token操作`);
    const token = get_token();
    if (!token) {
        const msg = `check_token_processor::to_name=${to_name}|token为假值，不需要执行check_token操作，check_token_type=002`;
        console.log(msg);
        return Q.resolve({
            check_token_type: `002`,
            msg,
        });
    }

    const defer = Q.defer();

    check_token().then(
        function (data) {
            const flag = data.flag;
            if (flag) {
                const msg = `check_token_processor::to_name=${to_name}|check_token操作验证通过，check_token_type=101`;
                if (debug) console.log(msg);
                defer.resolve({
                    check_token_type: `101`,
                    msg,
                });
                defer_login_completed.resolve();
                return;
            }

            const msg = `check_token_processor::to_name=${to_name}|check_token操作验证不通过，check_token_type=102`;
            if (debug) console.log(msg);
            defer.resolve({
                check_token_type: `102`,
                msg,
            });

            //不要进行reject，否则要进行重置
            //重置操作现在在logout
            // defer_login_completed.reject();
        },
        function () {
            const msg = `check_token_processor::to_name=${to_name}|check_token操作验证不通过，check_token_type=901`;
            if (debug) console.log(msg);
            defer.resolve({
                check_token_type: `901`,
                msg,
            });
        }
    );

    return defer.promise;

};

const next = handler;

const get_login_completed_promise = function () {
    return defer_login_completed.promise;
};

const logout = function () {
    defer_login_completed = Q.defer();
};

export default {
    next,
    get_login_completed_promise,
    logout,
}
