export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '',
      component: () => import ('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        // {
        //   path: '/hotel/admin/my-order-list',
        //   name: 'hotel-admin-my-order-list',
        //   component: () => import ('@/page/distributor/hotel/admin/hotelManageOrder'),
        //   meta: {
        //     nav_list_for_admin_layout_head_nav: [
        //       {name: `酒店订单`},
        //       {name: `我的订单`},
        //     ],
        //   },
        // },
        {
          path: '/hotel/admin/cancel-order-list',
          name: 'hotel-admin-cancel-order-list',
          component: () => import ('@/page/distributor/hotel/admin/hotelManageCancelOrder'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `酒店订单`},
              {name: `取消订单`},
            ],
          },
        },
        {
          path: '/hotel/admin/hotel-manage-order-info',
          name: 'hotel-admin-hotelManageOrderInfo',
          component: () => import ('@/page/distributor/hotel/admin/hotelManageOrderInfo'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `酒店订单`},
              {name: `订单详情`},
            ],
          },
        },
      ]
    },
  ]
};
