export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/train/back',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/distributor/train/back/train-order-payment',
          name: 'distributor-train-back-train-order-payment',
          component: () => import(/* webpackChunkName: "page-distributor-train-back-train-order-payment" */ '@/page/distributor/train/back/train-order-payment/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `火车票订单`},
              {name: `火车票`},
              {name: `支付`},
            ],
          },
        },
      ]
    },
  ]
};
