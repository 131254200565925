export default {
    path: '',
    component: () => import(`@/page/distributor/component/noLogin-layout/1.0.1/index.vue`),
    children: [
        {
            path: '/distributor/contactUs',
            name: 'distributor-contactUs',
            component: () => import(/* webpackChunkName: "page-distributor-other-contactUs" */ './1.0.1/index.vue'),
            meta: {
                enable_check_token: false,
                skip_check_token: true,
            }
        },
    ]
};
