export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/air-list',
            name: 'air-list',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-airList" */ './1.0.6/index.vue')
        },
    ]
}
