//---引用第三方---------------------------------------------------------------------------------------------------------
import _ from 'underscore';
import moment from 'moment';
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //默认主题
import ECharts from 'vue-echarts'
import {use} from 'echarts/core'
// 如果你实在需要全量引入 ECharts 从而无需手动引入模块，只需要在代码中添加：
// import "echarts";
// 手动引入 ECharts 各模块来减小打包体积
import {
    BarChart,
    LineChart,
    PieChart,
    MapChart,
    RadarChart,
    ScatterChart,
    EffectScatterChart,
    LinesChart
} from 'echarts/charts'
import {
    GridComponent,
    PolarComponent,
    GeoComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
    VisualMapComponent,
    DatasetComponent,
    ToolboxComponent,
    DataZoomComponent
} from 'echarts/components'
import {CanvasRenderer, SVGRenderer} from "echarts/renderers";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

//---引用本项目---------------------------------------------------------------------------------------------------------
import App from './App.vue';
import aRouter from './router';
import init_router from './router/init_router';
import store from './lib/store';

// import './common-style/theme/custom-theme-001/theme/index.css'; //自定义主题
import './common-style/common/index.less'; //全局样式入口

import vue_plugin_global_method from './lib/vue-plugin/global-method';
// import OverwriteElButton from './component/overwrite-element-ui/el-button';
// import OverwriteElDialog from './component/overwrite-element-ui/el-dialog';

import init_baidu_tongji from './lib/common-service/init_baidu_tongji';

import {STATUS} from "./lib/deprecated/utils/status";

//---Vue.use()----------------------------------------------------------------------------------------------------------
Vue.config.productionTip = false;

// Vue.use(ElementUI);
Vue.use(ElementUI, {size: 'small'});
Vue.use(vue_plugin_global_method);
Vue.use(VueAwesomeSwiper);
// Vue.use(OverwriteElButton);
// Vue.use(OverwriteElDialog);

const use_ECharts = function () {
    // 全局注册组件（也可以使用局部注册）
    Vue.component('v-chart', ECharts);

    use([
        BarChart,
        LineChart,
        PieChart,
        MapChart,
        RadarChart,
        ScatterChart,
        EffectScatterChart,
        LinesChart,
        GridComponent,
        PolarComponent,
        GeoComponent,
        TooltipComponent,
        LegendComponent,
        TitleComponent,
        VisualMapComponent,
        DatasetComponent,
        CanvasRenderer,
        SVGRenderer,
        ToolboxComponent,
        DataZoomComponent
    ]);
};
use_ECharts();

//---init---------------------------------------------------------------------------------------------------------------
//加快webpack热更新（潘磊找的方法）
//第二步：调用init_router方法
init_router();
init_baidu_tongji();

Vue.prototype.$moment = moment;
Vue.prototype._ = _;
Vue.prototype.SUCCESS_CODE = STATUS.success;

//----------------------------------------------------------------------------------------------------------------------
const aApp = new Vue({
    router: aRouter,
    store,
    render: h => h(App)
}).$mount('#app');
// global.aApp = aApp;
// global.aRouter = aRouter;
