export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        // // 机票-前台订单支付页（兼容旧的路由名称，先不要删除）
        // {
        //     path: '/airPay-old',
        //     name: '订单支付',
        //     component: () => import(/* webpackChunkName: "page-distributor-air-ticket-airPay" */ '@/page/distributor/air-ticket/airPay/1.0.2/index.vue'),
        // },
        // 机票-前台订单支付页
        {
            path: '/airPay',
            name: 'air-pay',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-airPay" */ './1.0.2/index.vue'),
        },
    ]
}
