import to_boolean from 'nllib/src/convert/to_boolean';

const get_enable_keep_alive = function (pParameter) {
    if (!pParameter) pParameter = {};
    const $route = pParameter.$route || {};

    const meta = $route.meta || {};
    const __enable_keep_alive = meta.enable_keep_alive;
    const enable_keep_alive = to_boolean(__enable_keep_alive, true);
    return enable_keep_alive;
};
export default get_enable_keep_alive;
