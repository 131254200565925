import get_enable_check_token from '@/lib/common-service/route_helper/get_enable_check_token';
import get_enable_keep_alive from '@/lib/common-service/route_helper/get_enable_keep_alive';

export default {
    install(Vue) {
        Vue.prototype.$showMsg = function (pParameter) {
            const __this = this;

            const color = pParameter.color || `info`;
            const msg = pParameter.msg || ``;

            const snackbar = __this.$root.$refs.aApp.snackbar;
            snackbar.msg = msg;
            snackbar.color = color;
            snackbar.show = true;
        };

        Vue.prototype.$getAppConfig = function () {
            return global.appConfig;
        };

        Vue.mixin({
            computed: {

                enable_check_token() {
                    const __this = this;
                    const $route = __this.$route;
                    return get_enable_check_token({
                        $route,
                    });
                },

                enable_keep_alive() {
                    const __this = this;
                    const $route = __this.$route;
                    return get_enable_keep_alive({
                        $route,
                    });
                },
            },
        })

    }
}
