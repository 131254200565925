export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/train/front/ticket-change-search',
      name: 'distributor-train-front-ticket-change-search',
      component: () => import(/* webpackChunkName: "page-distributor-train-front-ticket-change-search" */ './index.vue'),
    },
  ]
};
