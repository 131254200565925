export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/easy-pay',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/distributor/easy-pay/easy-pay-account',
          name: 'distributor-easy-pay-easy-pay-account',
          component: () => import(/* webpackChunkName: "page-distributor-easy-pay-easy-pay-account" */ '@/page/distributor/easy-pay/easy-pay-account/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `轻松付` },
              { name: `轻松付账户` },
            ],
          },
        },
      ]
    },
  ]
};
