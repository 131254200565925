export default {
    path: '',
    component: () => import(`@/page/distributor/component/noLogin-layout/1.0.1/index.vue`),
    children: [
        {
            path: '/distributor/FAQ',
            name: 'distributor-FAQ',
            component: () => import(/* webpackChunkName: "page-distributor-other-FAQ" */ './index.vue'),
            meta: {
                skip_check_token: true,
            }
        },
    ]
};
