export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/tour/front/select-city',
            name: 'distributor-tour-front-select-city',
            meta: {
                footer_margin_none: true,
                // need_alive: true,
            },
            component: () => import(/* webpackChunkName: "page-distributor-tour-front-select-city" */ './index.vue'),
        },
    ]
};
