export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/open-api/admin/api-flight-config',
                    name: 'open-api-admin-api-flight-config',
                    component: () => import(/* webpackChunkName: "page-distributor-open-api-config-open-api-flight-config" */ './1.0.0/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            {name: `接口对接配置`},
                            {name: `机票对接配置`},
                        ]
                    },
                },
            ]
        },
    ]
};
