export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/tour/front/recommend-product',
            name: 'distributor-tour-front-recommend-product',
            component: () => import(/* webpackChunkName: "page-distributor-tour-front-recommend-product" */ './index.vue'),
        },
    ]
};
