export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        //机票-后台-我的订单列表
        // {
        //   path: '/air-ticket/admin/purchase-order-list',
          // name: 'air-ticket-admin-purchase-order-list',
          // component: () => import('@/page/distributor/air-ticket/admin/purchase-order-list/index.vue'),
          // component: () => import('@/page/distributor/air-ticket/admin/1.0.0/purchase-order-list/index.vue'),
          // component: () => import('@/page/distributor/air-ticket/admin/1.0.1/purchase-order-list/index.vue'),
        //   meta: {
        //     nav_list_for_admin_layout_head_nav: [
        //       { name: `国内机票订单` },
        //       { name: `我的订单` },
        //     ],
        //   },
        // },
        //机票-后台-改签订单列表
        // {
        //   path: '/air-ticket/admin/change-order-list',
        //   name: 'air-ticket-admin-change-order-list',
        //   component: () => import('@/page/distributor/air-ticket/admin/change-order-list/1.0.1/index.vue'),
        //   meta: {
        //     nav_list_for_admin_layout_head_nav: [
        //       { name: `国内机票订单` },
        //       { name: `改签订单` },
        //     ],
        //   },
        // },
        //机票-后台-退票订单列表
        // {
        //   path: '/air-ticket/admin/refund-order-list',
        //   name: 'air-ticket-admin-refund-order-list',
        //   component: () => import('@/page/distributor/air-ticket/admin/refund-order-list/index.vue'),
        //   meta: {
        //     nav_list_for_admin_layout_head_nav: [
        //       { name: `国内机票订单` },
        //       { name: `退票订单` },
        //     ],
        //   },
        // },

        //机票-后台-订单详情
        /*{
          path: '/air-ticket/admin/airDetails',
          name: 'air-ticket-admin-airDetails',
          component: () => import('@/page/distributor/air-ticket/airDetails.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `订单详情` },
            ],
          },
        },*/
        // 机票-后台-机票改签页面
        // {
        //   path: '/air-ticket/admin/ticketChanging',
        //   name: 'air-ticket-admin-ticketChanging',
        //   component: () => import('@/page/distributor/air-ticket/ticketChanging/1.0.2/index.vue'),
        //   meta: {
        //     nav_list_for_admin_layout_head_nav: [
        //       { name: `国内机票订单` },
        //       { name: `机票改签` },
        //     ],
        //   },
        // },
        // 机票-后台-机票往返改签页面
        {
          path: '/air-ticket/admin/twoChanging',
          name: 'air-ticket-admin-twoChanging',
          component: () => import('@/page/distributor/air-ticket/twoChanging.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `机票改签` },
            ],
          },
        },
        // 机票-后台-机票改签支付页面
        {
          path: '/air-ticket/admin/changPay',
          name: 'air-ticket-admin-changPay',
          component: () => import('@/page/distributor/air-ticket/changPay/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `机票支付` },
            ],
          },
        },
        // 机票-后台-机票退票页面
        // {
        //   path: '/air-ticket/admin/dishonour',
        //   name: 'air-ticket-admin-dishonour',
        //   // component: () => import('@/page/distributor/air-ticket/dishonour.vue'),
        //   component: () => import('@/page/distributor/air-ticket/admin/dishonour/index.vue'),
        //   meta: {
        //     nav_list_for_admin_layout_head_nav: [
        //       { name: `国内机票订单` },
        //       { name: `机票退票` },
        //     ],
        //   },
        // },
        // 机票-后台-机票往返退票页面
        {
          path: '/air-ticket/admin/twoDishonour',
          name: 'air-ticket-admin-twoDishonour',
          component: () => import('@/page/distributor/air-ticket/twoDishonour.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `机票退票` },
            ],
          },
        },
        // 机票-后台-机票航变
        /*{
          path: '/air-ticket/admin/airChange',
          name: 'air-ticket-admin-airChange',
          // component: () => import('@/page/distributor/air-ticket/admin/air-change.vue'),
          component: () => import('@/page/distributor/air-ticket/admin/air-change/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `机票航变` },
            ],
          },
        },*/
        // 机票-后台-行程订单
        {
          path: '/air-ticket/admin/strokeOrder',
          name: 'air-ticket-admin-strokeOrder',
          component: () => import('@/page/distributor/air-ticket/admin/strokeOrder.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `行程订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/strokeOrderInfo',
          name: 'air-ticket-admin-strokeOrderInfo',
          component: () => import('@/page/distributor/air-ticket/admin/component/strokeOrderInfo/1.0.1/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `申请行程订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/ItineraryNotice',
          name: 'air-ticket-admin-ItineraryNotice',
          component: () => import('@/page/distributor/air-ticket/admin/ItineraryNotice'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国内机票订单` },
              { name: `行程通知单` },
            ],
          },
        },
        //国际机票
        {
          path: '/air-ticket/admin/international/list',
          name: 'air-ticket-admin-international-list',
          component: () => import('@/page/distributor/air-ticket/internationalAir/admin'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国际机票订单` },
              { name: `我的订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/change/order',
          name: 'air-ticket-admin-change-order',
          component: () => import('@/page/distributor/air-ticket/internationalAir/admin/changingOrder'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国际机票订单` },
              { name: `改签订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/cancel/order',
          name: 'air-ticket-admin-cancel-order',
          component: () => import('@/page/distributor/air-ticket/internationalAir/admin/cancelOrder'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国际机票订单` },
              { name: `退票订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/cancellation/order',
          name: 'air-ticket-admin-cancellation-order',
          component: () => import('@/page/distributor/air-ticket/internationalAir/admin/refundOrder'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国际机票订单` },
              { name: `退款订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/refund/order',
          name: 'air-ticket-admin-refund-order',
          component: () => import('@/page/distributor/air-ticket/internationalAir/admin/cancellationOrder'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国际机票订单` },
              { name: `废票订单` },
            ],
          },
        },
        {
          path: '/air-ticket/admin/international/list/detail',
          name: 'air-ticket-admin-international-list-detail',
          component: () => import('@/page/distributor/air-ticket/internationalAir/admin/component/detail'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `国际机票订单` },
              { name: `订单详情` },
            ],
          },
        },
      ]
    },
  ]
};
