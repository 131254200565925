export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
      {
        path: '',
        component: () => import('@/page/distributor/component/admin-layout/index.vue'),
        children: [
          {
            path: '/air-ticket/refund-list',
            name: 'refund-list',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-admin-refund-money-List" */ './1.0.1/index.vue'),
            meta: {
              nav_list_for_admin_layout_head_nav: [
                {name: `机票`},
                {name: `退款列表`},
              ],
            },
          },
        ]
      }
    ]
  };
