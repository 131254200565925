export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            name: 'distributor-notice-list',
            path: '/distributor/notice/notice-list',
            meta: {
                footer_margin_none: true,
            },
            component: () => import(/* webpackChunkName: "page-distributor-notice-notice-list" */ `./index.vue`),
        },
    ]
};

