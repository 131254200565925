
export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/train/front/train-change-payment',
      name: 'distributor-train-front-train-change-payment',
      component: () => import(/* webpackChunkName: "page-distributor-train-front-train-change-payment" */ './index.vue'),
    },
  ]
};
