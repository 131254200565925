const routing = {
    state: {
        routeList: []
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.routeList = routers
        }
    },
    actions: {
    }
}

export default routing
