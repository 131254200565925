const extend = require('extend');
const Q = require('q');
const axios = require('axios');

const __request = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }

    const defaultParameter = {};
    defaultParameter.method = `post`;
    defaultParameter.url = ``;
    defaultParameter.data = {};

    const parameter = extend(true, {}, defaultParameter, pParameter);

    return Q.when()
        .then(function () {
            return axios({
                method: parameter.method,
                url: parameter.url,
                data: parameter.data,
            });
        })
        .then(function (response) {
            const data = response.data;
            const code = data.code;

            let r;
            switch (code) {
                case `200`:
                    r = Q.resolve(data);
                    break;

                default:
                    r = Q.reject(data);
                    break;
            }
            return r;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};

module.exports = __request;
