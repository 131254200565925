import _ from 'underscore';
import to_boolean from 'nllib/src/convert/to_boolean';

//加快webpack热更新（潘磊找的方法）
//第一步：get_routes_by_require不在./src/router/index.js时调用
const get_routes_by_require = function () {
    const require_route = require.context(
        // 其组件目录的相对路径
        '@/page',
        // 是否查询其子目录
        true,
        // 匹配基础组件文件名的正则表达式
        /\/route\.js$/
    );
    const list_001 = _.chain(require_route.keys())
        .map(function (m) {
            const __path = m;
            const route = require_route(__path);
            return route.default;
        })
        .value()
    ;
    // console.log(`list_001.length=${list_001.length}`);
    const list_002 = _.chain(list_001)
        .filter(function (m) {
            // const children = m.children;
            // if (children) {
            //     if (children.length > 0) {
            //         const first = children[0];
            //         if (first.name === `admin-home`) {
            //             debugger
            //         }
            //     }
            // }

            const meta = m.meta;
            if (!meta) return true;
            const data_for_get_routes_by_require = meta.data_for_get_routes_by_require;
            if (!data_for_get_routes_by_require) return true;
            const enable = to_boolean(data_for_get_routes_by_require.enable, true);
            if (enable) return true;
            return false;
        })
        .value()
    ;
    // console.log(`list_002.length=${list_002.length}`);
    return list_002
};
export default get_routes_by_require;
