import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/v-for-test',
            name: 'admin-v-for-test',
            component: () => import(/* webpackChunkName: "page-admin-default-v-for-test" */ `./index.vue`),
            meta: {
                data_for_admin_layout: {
                    data_for_history_tag: {
                        entity: {
                            name: "v-for-test"
                        }
                    },
                    data_for_breadcrumb: {
                        entity_list: [
                            {
                                name: "示例功能"
                            },
                            {
                                name: "v-for-test"
                            }
                        ]
                    }
                }
            },

        },
    ],
};
