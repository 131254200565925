export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/personage-info',
                    name: 'personage-info',
                    component: () => import(/* webpackChunkName: "page-distributor-yinqianbao-back-personageInfo" */ './index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            { name: `个人中心` },
                            { name: `个人信息设置` },
                        ],
                    },
                },
            ]
        },
    ]
};
