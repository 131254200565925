export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/credit/back',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/distributor/account/alipay-recharge',
                    name: 'distributor-account-alipay-recharge',
                    component: () => import(/* webpackChunkName: "page-distributor-alipay-recharge-order" */ './1.0.0/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            {name: `余额记账`},
                            {name: `余额账户`},
                            {name: `支付宝转账充值`},
                        ],
                    },
                },
            ]
        },
    ]
};
