const Q = require('q');
const __request = require(`./__request`);

const api_system_get_server_config = function (pParameter) {
    return Q.when()
        .then(function () {
            return __request({
                method: 'post',
                url: '/api/system/get_server_config',
                data: {},
            });
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};

module.exports = api_system_get_server_config;
