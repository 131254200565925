export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/tour/front/pay',
            name: 'distributor-tour-front-pay',
            component: () => import(/* webpackChunkName: "page-distributor-tour-front-pay" */ './1.0.1/index.vue'),
        },
    ]
};
