export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/flight-pnr',
      name: 'flight-pnr',
      component: () => import(/* webpackChunkName: "page-distributor-air-ticket-flight-pnr" */ `./1.0.4/index.vue`),
      // meta: {
      //   entity_for_history_tag: {
      //     name: `国内机票`,
      //   },
      //   entity_list_for_breadcrumb: [
      //     { name: `示例功能` },
      //     { name: `桌面` },
      //   ],
      // },
    }
  ],
};
