export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/tour/front/travel/order',
      name: 'distributor-tour-front-travel-order',
      component: () => import(/* webpackChunkName: "page-distributor-tour-front-cut-position-reserva" */ './index.vue'),
    },
  ]
};
