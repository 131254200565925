export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/tour/front/search',
      name: 'distributor-tour-front-search',
      meta: {
        footer_margin_none: true,
      },
      component: () => import(/* webpackChunkName: "page-distributor-tour-front-search" */ './1.0.1/index.vue'),
    },
  ]
};
