import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/sample-data-detail',
            name: 'admin-sample-data-detail',
            component: () => import(/* webpackChunkName: "page-admin-default-sample-data-detail" */ /* webpackChunkName: "page-admin-default-sample-data-detail" */ `./index.vue`),
            meta: {
                data_for_admin_layout: {
                    data_for_history_tag: {
                        entity: {
                            name: `示例数据详情`,
                        },
                    },
                    data_for_breadcrumb: {
                        entity_list: [
                            {name: `示例功能`},
                            {name: `示例数据`},
                            {name: `示例数据详情`},
                        ],
                    },
                    data_for_left_menu: {
                        get_active_route_name(pParameter) {
                            // if (!pParameter) pParameter = {};
                            // const all_route_list = pParameter.all_route_list;
                            return `admin-sample-data-list`;
                        },
                    },
                },
            },
        },

        {
            path: '/admin/sample-data-detail-disable-keep-alive',
            name: 'admin-sample-data-detail-disable-keep-alive',
            component: () => import(`./index.vue`),
            meta: {
                enable_keep_alive: false,

                data_for_admin_layout: {
                    data_for_history_tag: {
                        entity: {
                            name: `示例数据详情`,
                        },
                    },
                    data_for_breadcrumb: {
                        entity_list: [
                            {name: `示例功能`},
                            {name: `示例数据`},
                            {name: `示例数据详情`},
                        ],
                    },
                    data_for_left_menu: {
                        get_active_route_name(pParameter) {
                            // if (!pParameter) pParameter = {};
                            // const all_route_list = pParameter.all_route_list;
                            return `admin-sample-data-list`;
                        },
                    },
                },
            },
        },

    ],
};
