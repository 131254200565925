export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/air-ticket',
            name: 'air-ticket',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-flight-home" */ './1.0.3/index.vue')
        },
    ]
}
