const selectCity = {
    state: {
        city: ''
    },
    mutations: {
        SET_SELECTCITY: (state, city) => {
            state.city = city
        }
    },
    actions: {

    }
}

export default selectCity
