export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '',
      component: () => import ('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/hotel/admin/my-order-list',
          name: 'hotel-admin-my-order-list',
          component: () => import ('@/page/distributor/hotel/back/hotel-order-list/1.0.1/index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              {name: `酒店订单`},
              {name: `我的订单`},
            ],
          },
        },
      ]
    },
  ]
};
