export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/credit/back',
      component: () => import('@/page/distributor/component/admin-layout/index.vue'),
      children: [
        {
          path: '/distributor/credit/back/account',
          name: 'distributor-credit-back-account',
          component: () => import(/* webpackChunkName: "page-distributor-credit-creditAccount" */ './index.vue'),
          meta: {
            nav_list_for_admin_layout_head_nav: [
              { name: `授信` },
              { name: `授信账户` },
            ],
          },
        },
      ]
    },
  ]
};
