export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/account/message',
            name: 'distributor-account-message',
            component: () => import(/* webpackChunkName: "page-distributor-accounts-component" */ './message.vue'),
            meta: {
                nav_list_for_admin_layout_head_nav: [
                    { name: `胤钱宝` },
                    { name: `预存款充值` },
                ],
            },
        },
    ]

};
