import Q from 'q'
import router from '@/router'

const redirect_to_login_page = function (pParameter) {
    router.replace({
        name: `login`,
    });
};

export default redirect_to_login_page;
