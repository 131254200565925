import extend from "extend";
import _ from 'underscore';
import Q from 'q';

let routes_level_one;

const __get_all_route_list = function (pRoutes) {
  const list_001 = pRoutes;

  const list_002 = _.chain(list_001)
    .filter(function (m) {
      return _.isArray(m.children);
    })
    .map(function (m) {
      const children = _.chain(m.children)
        .map(function (m) {
          const o = extend(true, {}, m);
          return o;
        })
        .value()
      ;
      return children;
    })
    .flatten()
    .value()
  ;

  let list_003 = [];
  if (list_002.length > 0) {
    list_003 = __get_all_route_list(list_002);
  }

  let list = [];
  list = list.concat(list_001);
  list = list.concat(list_003);
  return list;

};

let __all_route_list;
const get_all_route_list = function (p_routes_level_one) {
  if (!__all_route_list) {
    __all_route_list = __get_all_route_list(p_routes_level_one);
  }
  return __all_route_list;
};

const init = function (pParameter) {
  if (!pParameter) pParameter = {};
  const __routes_level_one = pParameter.routes_level_one;
  if (!__routes_level_one) {
    const msg = 'init parameter error, code=001';
    console.error(msg);
    console.trace();
    return Q.reject({msg: msg});
  }

  routes_level_one = __routes_level_one;
  get_all_route_list(routes_level_one);
};

export default {
  init,
  get_all_route_list,
}
