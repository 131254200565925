export default {
    name: 'help-center',
    path: '/help-center',
    component: () => import(/* webpackChunkName: "page-help-center" */ `./1.0.1/index.vue`),
    meta: {
        enable_check_token: false,
        skip_check_token: true,
        footer_margin_none: true,
    },
};
