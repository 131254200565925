export default {
    name: ``,
    path: '',
    component: () => import(/* webpackChunkName: "page-supplier-registration-distributorLogin" */ `@/page/supplier/registration/index.vue`),
    children: [
        {
            name: `distributor-registration`,
            path: '/distributor/registration',
            component: () => import(/* webpackChunkName: "page-supplier-registration-distributorLogin" */ `./2.0.1/index.vue`),
            meta: {
                enable_check_token: false,
            },
        },
    ]
};
