import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import {Loading} from 'element-ui';
import {Notification} from "element-ui";

import api_system_get_p from "@/lib/data-service/system/api_system_get_p";

const log_key = `update-tips-processor::run_single`;

// const milliseconds = 1000;
const milliseconds = 60 * 1000;

const list_notification = [];
const notification_max_count = 5;

const get_log_time_format = function () {
    return `[${moment().format('YYYY-MM-DD HH:mm:ss')}]`;
};

const get_remote_commit_id = function () {
    return Q.when()
        .then(function () {
            return api_system_get_p();
        })
        .then(function (data) {
            console.log(data);

            const d = {
                remote_commit_id: ``,
            };

            const entity = data.entity;
            if (!entity) return Q.resolve(d);
            const commit_id = entity.commit_id;
            if (!commit_id) return Q.resolve(d);

            d.remote_commit_id = commit_id;
            return Q.resolve(d);
        })
        .catch(function (ex) {
            if (!ex) return Q.reject(ex);
            const enable_log_error = !ex.disable_log_error;
            if (!enable_log_error) return Q.reject(ex);
            console.error(ex);
            console.trace();
            if (ex.stack) console.error(ex.stack);
            return Q.reject(ex);
        })
        ;
};

const get_local_commit_id = function () {
    const appConfig = global.appConfig;
    if (!appConfig) return;
    const p = appConfig.p;
    if (!p) return;
    const commit_id = p.commit_id;
    return commit_id;
};

const run_single = function () {
    return Q.when()
        .then(function () {
            return get_remote_commit_id();
        })
        .then(function (data) {
            const remote_commit_id = data.remote_commit_id;

            const local_commit_id = get_local_commit_id();

            if (!remote_commit_id) return;
            if (!local_commit_id) return;

            console.log(get_log_time_format(), log_key, `remote_commit_id=${remote_commit_id}`, `local_commit_id=${local_commit_id}`);

            if (remote_commit_id === local_commit_id) {
                console.log(get_log_time_format(), log_key, `本地版本号与远程版本号一致，不需要提示更新！`);
            } else {
                console.log(get_log_time_format(), log_key, `本地版本号与远程版本号不一致，需要提示更新！`);

                const aNotification = Notification.info({
                    customClass: `update-tips-processor-notification`,
                    title: `发现有可用更新`,
                    message: `点击更新（${moment().format('YYYY-MM-DD HH:mm:ss')}）`,
                    duration: 0,
                    onClick() {
                        const aLoading = Loading.service({});
                        setTimeout(function () {
                            window.location.reload();
                        }, 1000);
                    },
                    onClose() {
                        // if (list_notification.length > 0) {
                        //     list_notification.pop();
                        // }
                    },
                });
                list_notification.push(aNotification);
            }
        })
        .catch(function (ex) {
            if (!ex) return Q.reject(ex);
            const enable_log_error = !ex.disable_log_error;
            if (!enable_log_error) return Q.reject(ex);
            console.error(ex);
            console.trace();
            if (ex.stack) console.error(ex.stack);
            return Q.reject(ex);
        })
        ;
};

const recycling_resources = function () {
    if (list_notification.length > notification_max_count) {
        const aNotification = list_notification.shift();
        aNotification.close();
    }
};

const init = function () {
    const appConfig = global.appConfig;
    if (!appConfig) return;
    const p = appConfig.p;
    if (!p) return;
    const enable_check = p.enable_check;
    if (!enable_check) return;

    run_single().then(function () {
        setInterval(function () {
            run_single();
            recycling_resources();
        }, milliseconds);
    });
};

export default {
    init,
}
