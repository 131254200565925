export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/tour/front/home',
            name: 'distributor-tour-front-home',
            meta: {
                footer_margin_none: true,
            },
            component: () => import(/* webpackChunkName: "page-distributor-tour-front-home" */ './1.0.6/index.vue'),
        },
    ]
};
