export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
      {
        path: '',
        component: () => import('@/page/distributor/component/admin-layout/index.vue'),
        children: [
          {
            path: '/pay-success/message',
            name: 'pay-success-message',
            component: () => import(/* webpackChunkName: "page-distributor-component-paySuccess" */ './index.vue'),
            meta: {
              nav_list_for_admin_layout_head_nav: [
                {name: `支付提示页面`},
              ],
            },
          }
        ]
      },
    ]
  };
