export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/hotel/front/search',
      name: 'distributor-hotel-front-search',
      meta: {
        footer_margin_none: true,
        need_alive: true,
      },
      component: () => import(/* webpackChunkName: "page-distributor-hotel-front-hotel-search" */ '@/page/distributor/hotel/front/hotel-search/1.0.4/index.vue'),
    },
  ]
};
