export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/air-ticket/admin/dishonour',
                    name: 'air-ticket-admin-dishonour',
                    // component: () => import(/* webpackChunkName: "page-distributor-air-ticket-admin-dishonour" */ '@/page/distributor/air-ticket/dishonour.vue'),
                    component: () => import('@/page/distributor/air-ticket/admin/dishonour/1.0.1/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            { name: `国内机票订单` },
                            { name: `机票退票` },
                        ],
                    },
                },
            ]
        }
    ],
}