export default {
  path: '',
  component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
  children: [
    {
      path: '/distributor/tour/front/write-traveller',
      name: 'distributor-tour-front-write-traveller',
      component: () => import(/* webpackChunkName: "page-distributor-tour-front-write-traveller" */ './index.vue'),
    },
  ]
};
