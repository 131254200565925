export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
      {
        path: '/admin/buy-insurance',
        name: 'admin-buy-insurance',
        meta: {
          footer_margin_none: true,
        },
        component: () => import(/* webpackChunkName: "page-distributor-insurance-buy-insurance" */ './index.vue'),
      },
    ]
  };
  