import import_latest_version_module from './import_latest_version_module';

export default {
    path: '/redirect',
    name: 'redirect',
    component: () => import_latest_version_module(),
    meta: {
        enable_check_token: false,
    },
};
