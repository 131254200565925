export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/distributor/train/back',
            component: () => import('@/page/distributor/component/admin-layout/index.vue'),
            children: [
                {
                    path: '/distributor/train/back/train-order-list',
                    name: 'distributor-train-back-train-order-list',
                    component: () => import(/* webpackChunkName: "page-distributor-train-back-train-order-list" */ './1.0.1/index.vue'),
                    meta: {
                        nav_list_for_admin_layout_head_nav: [
                            {name: `火车票管理`},
                            {name: `订单列表`},
                        ],
                    },
                },
            ]
        },
    ]
};
